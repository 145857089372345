const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://ehr4g28er0.execute-api.ap-southeast-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://5lsprkwzuh.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.10.2',
    HOSTNAME: 'https://company.testing.newcrest.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.newcrest.forwoodsafety.com',
    WEBSOCKET: 'wss://lssvqv4m17.execute-api.ap-southeast-2.amazonaws.com/testing'
  },
};

export default config;